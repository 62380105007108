import React from 'react'
import Base from '../../components/base/index'

export default (props) => {
    return (
        <Base>
            Welcome to about page
        </Base>
    )
}
